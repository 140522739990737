html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
}

/*nav styles: start here */
.nav-link {
  transition: opacity 200ms ease-in-out;
  text-transform: uppercase;
  font-weight: 600;
  color: #040404;
  overflow: hidden;
}
.nav-link::after {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  margin-top: 2px;
  border-radius: 2px;
  background: #040404;
  transition: width 200ms ease-in-out;
}

.active-nav-item > a::after,
.nav-link:hover::after {
  width: 100%;
}
.navbar-text {
  font-size: 1rem;
  font-weight: 600;
}
.navbar-toggler {
  border: 1px solid black;
}
.navbar-toggler-icon {
  height: 2px;
}
.nav-link:hover {
  color: black;
}
.custom-toggler.navbar-toggler {
  border-color: black;
}
.navbar-toggler {
  width: 47px;
  height: 34px;
}
.navbar-toggler .line {
  width: 100%;
  float: left;
  height: 2px;
  background-color: black;
  margin-bottom: 5px;
}
/*nav styles: end here */
/*Home nav styles: start here */
.home-container {
  max-width: 80%;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.home-container > div {
  width: 50%;
}
.home-img {
  width: 100%;
  height: auto;
}
.home-img-container {
  animation: right_to_left 1.5s ease;
}
.home-txt-container {
  animation: left_to_right 1.5s ease;
}
@keyframes right_to_left {
  from {
    transform: translateX(150%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes left_to_right {
  from {
    transform: translateX(-150%);
  }
  to {
    transform: translateX(0);
  }
}
/*Home nav styles: end here */
/*Footer nav styles: start here */
#footer-col1, #footer-col2 {
  margin-top: 3rem;
}
.page-footer {
  background-color: black;
}
li, #footer-header {
  color: white;
}
i:hover {
  opacity: 70%;
}
/*Footer nav styles: End here */
/*About nav styles: Start here */
.about-container {
  max-width: 1200px;
  margin: 0 auto;
}
.about-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header {
  width: 100%;
  margin-top: 5%;
  text-align: center;
}
.about-img {
  width: 200px;
  height: 200px;
}
.header-title {
  font-size: 5vw;
  display: inline;
  border-bottom: 5px solid #7D18FD;
}
.left-side {
  width: 30%;
}
.right-side {
  margin-top: 4%;
  width: 70%;
}
.about-img-container {
  padding: 10% 0;
}
.about-paragraph {
  font-size: 28px;
}
/*About nav styles: End here */
/*project nav styles: Start here */
.projects {
  max-width: 1200px;
  margin-top: 4%;
  margin: 0 auto;
}
.thumbnail-container {
  margin-top: 3%;
  justify-content: center;
  display: flex;
}
#view-btn {
  margin-right: 10px;
}
.card-title {
  text-align: center;
}
.card-txt {
  height: 120px;
}
.card-img-top {
  width: 100%;
  height: 250px;
}
.card {
  height: 480px;
  width: 400px;
}
.view-button {
  font-size: 15px;
}
@media screen and (max-width: 1300px) {
  .card-title {
    height: 53px
  }
  .card-txt {
    height: 130px;
  }
  .card {
    height: 520px;
    width: 370px;
  }
  .about-img {
    width: 180px;
    height: 180px;
  }
  .about-paragraph {
    font-size: 24px;
  }
  .home-txt1 {
    font-size: 40px;
  }
  .home-txt2 {
    font-size:30px;
  }
}
@media screen and (max-width: 1110px) {
  .thumbnail-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .card-title {
    height: 80px
  }
  .card-txt {
    height: 100px;
  }
  .card {
    margin-top: 2%;
    height: 510px;
    width: 400px
  }
  .about-img {
    width: 160px;
    height: 160px;
  }
  .about-paragraph {
    font-size: 20px;
  }
  .home-txt1 {
    font-size: 34px;
  }
  .home-txt2 {
    font-size:24px;
  }
  .img-skill {
    width: 50px;
    height: 50px;
  }
}
@media screen and (max-width: 700px) {
  .about-img {
    width: 140px;
    height: 140px;
  }
  .about-paragraph {
    font-size: 18px;
  }
  .home-txt1 {
    font-size: 29px;
  }
  .home-txt2 {
    font-size:19px;
  }
  .img-skill {
    width: 50px;
    height: 50px;
  }
}
@media screen and (max-width: 660px) {
  .about-img {
    width: 130px;
    height: 130px;
  }
  .about-paragraph {
    font-size: 17px;
  }
  .home-txt1 {
    font-size: 24px;
  }
  .home-txt2 {
    font-size:15px;
  }
}
@media screen and (max-width: 500px) {
  .home-txt1 {
    font-size: 18px;
  }
  .home-txt2 {
    font-size: 12px;
  }
}
@media screen and (max-width: 400px) {
  .about-img {
    width: 100px;
    height: 100px;
  }
  .about-paragraph {
    font-size: 14px;
  }
  .card-txt {
    height: 110px;
  }
  .card {
    height: 520px;
    width: 340px
  }
  .home-txt1 {
    font-size: 17px;
  }
  .home-txt2 {
    font-size: 14px;
  }
  .img-skill {
    width: 20px;
    height: 20px;
  }
}
/*project nav styles: End here */
/*Img Container nav styles: Start here */
.img-container > div {
  width: 100%;
  margin-top: 4%;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  justify-content: space-evenly;
}
.img-skill {
  width: 180px;
  height: 180px;
}
/*Img Container nav styles: End here */